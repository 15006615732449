/* Brand Colors */
/* Color palette */
/* Gray colors */
.footer {
  width: 100%;
  bottom: 0;
  max-height: 96px;
  width: calc(100% - 40px);
  padding: 24px 20px 24px 20px;
  border-top: 1px solid #CDD2D4;
  float: left;
  color: #707070; }
  .footer img {
    display: inline-block; }
  .footer .font_caption2 {
    display: inline-block;
    float: right;
    padding-top: 4px; }
  @media (max-width: 600px) {
    .footer {
      padding: 28px 20px 28px 20px; }
      .footer .font_caption2 {
        display: block;
        float: left; }
      .footer img {
        display: block; } }
