/* Brand Colors */
/* Color palette */
/* Gray colors */
.voucher .details {
  float: none;
  text-align: center;
  color: #4a4a4a; }

.voucher .detailsBooking {
  float: none;
  color: #4a4a4a; }

.voucher .meetingPointTitle {
  padding-bottom: 10px; }

@media print {
  .voucher {
    max-width: 600px;
    margin: 0 auto; }
    .voucher .pictureContainer {
      display: none; }
    .voucher .details {
      display: none; }
    .voucher .borderPic {
      display: none; }
    .voucher .secondary, .voucher .error {
      color: #707070; }
    .voucher .buttonContainer {
      display: none; }
    .voucher .font_body1 {
      font-family: "Open Sans", sans-serif;
      font-display: auto;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 17px;
      letter-spacing: 0; }
    .voucher .font_headline5 {
      margin-bottom: 10px; }
    .voucher .featuredInfo {
      background-color: #f4f4f4; }
      .voucher .featuredInfo .container .pictureYellow {
        display: none; }
      .voucher .featuredInfo .container .picturePrint {
        display: inline-block; }
    .voucher .defaultLink {
      display: none; }
    .voucher .bookingContainer {
      box-shadow: none;
      border: 1px solid #CDD2D4; }
    .voucher .digest {
      margin-bottom: 20px;
      color: #707070; }
    .voucher .titletext .font_body1 {
      margin-bottom: 20px; }
    .voucher .defaultLink {
      color: #707070; } }

.voucher .fullLayout .digest .container .pictureContainer .tabPic {
  width: 600px; }
  @media (max-width: 600px) {
    .voucher .fullLayout .digest .container .pictureContainer .tabPic {
      width: calc(100% - 40px); } }
