/* Brand Colors */
/* Color palette */
/* Gray colors */
.modalBackground {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 10; }
  .modalBackground .modal {
    display: block;
    margin: auto;
    background-color: #FFFFFF;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px; }
    @media (min-width: 1121px) {
      .modalBackground .modal {
        width: 560px;
        height: auto; } }
    @media (min-width: 1024px) and (max-width: 1120px) {
      .modalBackground .modal {
        width: 560px;
        height: auto; } }
    @media (min-width: 601px) and (max-width: 1023px) {
      .modalBackground .modal {
        width: 560px;
        height: auto; } }
    @media (max-width: 600px) {
      .modalBackground .modal {
        width: calc(100% - 40px); } }
    .modalBackground .modal .content {
      position: relative;
      padding: 20px 20px 24px 20px;
      height: calc(100% - 40px); }
      .modalBackground .modal .content .closeIcon {
        width: 20px;
        height: 20px;
        background-size: 20px;
        cursor: pointer; }
      .modalBackground .modal .content .modalButton {
        position: absolute;
        right: 20px;
        top: 20px; }

@media (min-width: 1121px) {
  .modalBackground .modal {
    width: 560px;
    height: auto; } }

@media (min-width: 1024px) and (max-width: 1120px) {
  .modalBackground .modal {
    width: 560px;
    height: auto; } }

@media (min-width: 601px) and (max-width: 1023px) {
  .modalBackground .modal {
    width: 560px;
    height: auto; } }

@media (max-width: 600px) {
  .modalBackground .modal {
    width: calc(100% - 40px); }
    .modalBackground .modal .optionsContainer {
      padding-bottom: 20px; } }

.modalBackground .modal .modalContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column; }

.modalBackground .modal .modalTitle {
  padding-top: 8px;
  padding-bottom: 28px;
  color: #4a4a4a; }
