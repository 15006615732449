/* Brand Colors */
/* Color palette */
/* Gray colors */
.textArea {
  height: 100px;
  width: 100%;
  resize: none;
  border: 1px solid #CDD2D4;
  border-radius: 3px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 12px;
  outline: 0 none; }
