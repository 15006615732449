/* Brand Colors */
/* Color palette */
/* Gray colors */
.meetingPoint .details {
  float: none;
  text-align: center; }

.meetingPoint .detailsBooking {
  float: none; }

.meetingPoint .meetingPointTitle {
  padding-bottom: 10px; }

.meetingPoint .container .font_headline5 {
  margin-bottom: 30px;
  color: #4a4a4a; }

.meetingPoint .container .font_subtitle2 {
  margin-bottom: 10px; }

.meetingPoint .titleAdress {
  padding-top: 15px;
  color: #4a4a4a; }

.meetingPoint .subtitleAdress {
  color: #4a4a4a; }

.meetingPoint .defaultLink {
  display: block;
  padding: 12px 0 40px 0;
  color: #10c0bc;
  text-decoration: none; }
