/* Brand Colors */
/* Color palette */
/* Gray colors */
.billing .data {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  padding: 14px 0;
  border-top: 2px solid #10c0bc;
  border-bottom: 2px solid #10c0bc; }
  .billing .data .date, .billing .data .bill {
    display: inline-block;
    width: 30%; }
    .billing .data .date div, .billing .data .bill div {
      display: block;
      text-align: left; }
  .billing .data .bill div:first-child,
  .billing .data .date div:first-child {
    margin-bottom: 6px; }
  .billing .data .title {
    margin-bottom: 10px;
    float: left;
    width: 100%;
    padding: 3px 0; }
  .billing .data .account {
    width: 100%; }
    .billing .data .account div {
      display: inline-block; }

.billing .billData {
  width: 100%;
  margin-bottom: 30px; }
  .billing .billData .table {
    width: 100%;
    display: table; }
    .billing .billData .table .row {
      width: 100%;
      display: table-row; }
      .billing .billData .table .row .column {
        display: table-cell;
        border: 1px solid #CDD2D4;
        padding: 10px;
        text-align: center;
        height: 100%;
        vertical-align: middle;
        color: #707070; }
        .billing .billData .table .row .column.noBorder {
          border-right: 1px solid #CDD2D4;
          border-bottom: none;
          border-left: none;
          border-top: none; }
        .billing .billData .table .row .column.alignLeft {
          text-align: left;
          padding-left: 25px; }
        .billing .billData .table .row .column.alignRight {
          text-align: right;
          padding-right: 25px; }
      .billing .billData .table .row .column:nth-last-child(2) .noBorder {
        border-top: 1px solid #CDD2D4; }
