/* Brand Colors */
/* Color palette */
/* Gray colors */
.billing .data {
  width: 100%;
  margin-bottom: 30px; }
  .billing .data .title {
    margin-bottom: 10px;
    float: left;
    width: 100%;
    border-top: 2px solid #10c0bc;
    border-bottom: 2px solid #10c0bc;
    padding: 3px 0; }
  .billing .data .account, .billing .data .bill, .billing .data .date {
    width: 100%; }
    .billing .data .account div, .billing .data .bill div, .billing .data .date div {
      display: inline-block; }
    .billing .data .account .info, .billing .data .bill .info, .billing .data .date .info {
      padding: 0 0 0 10px; }

.billing .billData {
  width: 100%;
  margin-bottom: 30px; }
  .billing .billData .title {
    margin-bottom: 10px;
    float: left;
    width: 100%;
    border-top: 2px solid #10c0bc;
    border-bottom: 2px solid #10c0bc;
    padding: 3px 0; }
  .billing .billData .table {
    width: 100%;
    display: table; }
    .billing .billData .table .row {
      width: 100%;
      display: table-row; }
      .billing .billData .table .row .column {
        display: table-cell;
        border: 1px solid #CDD2D4;
        padding: 10px;
        text-align: center;
        height: 100%;
        vertical-align: middle;
        color: #707070; }
