/* Brand Colors */
/* Color palette */
/* Gray colors */
.price {
  width: 100px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  color: #4a4a4a; }
  .price .font_caption2 {
    color: #707070; }
  .price .font_headline5 {
    padding-top: 2px; }
