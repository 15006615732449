/* Brand Colors */
/* Color palette */
/* Gray colors */
.modalContainer:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: #10c0bc; }

.modalContainer .options > div {
  margin-bottom: 12px; }
