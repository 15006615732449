/* Brand Colors */
/* Color palette */
/* Gray colors */
.newBooking {
  display: inline-block;
  box-sizing: border-box;
  padding: 40px;
  text-align: center;
  width: 100%;
  border: 1px solid #CDD2D4;
  border-radius: 3px;
  margin-bottom: 40px;
  color: #4a4a4a; }
  .newBooking .textNewBooking {
    margin-bottom: 28px; }
  .newBooking .newBookingButton {
    width: 199px;
    height: 44px;
    border-radius: 3px;
    border: solid 1px #0E908D;
    color: #0E908D;
    background-color: #FFFFFF; }
