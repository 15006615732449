/* Brand Colors */
/* Color palette */
/* Gray colors */
.info {
  width: auto;
  padding: 0px 0px 15px 32px;
  text-align: left;
  display: inline-block;
  vertical-align: middle; }
  @media (max-width: 600px) {
    .info {
      width: calc(100% - 32px); } }
