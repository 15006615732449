/* Brand Colors */
/* Color palette */
/* Gray colors */
.titletext {
  width: 100%;
  float: left; }
  .titletext .font_h1 {
    margin-bottom: 28px; }
  .titletext .font_headline5 {
    margin-bottom: 20px; }
  .titletext .font_body1 {
    margin-top: 20px;
    margin-bottom: 40px; }
    .titletext .font_body1 ul li {
      list-style: none; }
      .titletext .font_body1 ul li:before {
        content: '- '; }
  .titletext ul {
    padding-inline-start: 0 !important; }
