/* Brand Colors */
/* Color palette */
/* Gray colors */
.featuredInfo {
  margin-bottom: 40px;
  width: 100%;
  background-color: rgba(253, 205, 72, 0.1); }
  @media (min-width: 1024px) and (max-width: 1120px) {
    .featuredInfo {
      width: 320px; } }
  @media (min-width: 1121px) {
    .featuredInfo {
      width: 320px; } }
  .featuredInfo .container {
    color: #4a4a4a;
    padding: 20px; }
    .featuredInfo .container .font_headline6 {
      margin-bottom: 20px; }
    .featuredInfo .container .font_body2 {
      margin-bottom: 20px;
      color: #4a4a4a; }
    .featuredInfo .container .mail {
      font-family: "Open Sans", sans-serif;
      font-display: auto;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 24px;
      letter-spacing: 0;
      margin-bottom: 12px; }
    .featuredInfo .container .phone {
      font-family: "Open Sans", sans-serif;
      font-display: auto;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 24px;
      letter-spacing: 0;
      margin-bottom: 20px; }
    .featuredInfo .container .pictureYellow {
      background-image: url("https://imgs-akamai.mnstatic.com/tools/privateArea/ic_helpcenter.png");
      background-repeat: no-repeat;
      width: 61px;
      height: 60px;
      background-size: 61px 61px;
      margin: 10px 0 28px; }
    .featuredInfo .container .picturePrint {
      display: none;
      background-image: url("https://imgs-akamai.mnstatic.com/tools/privateArea/ic_helpcenter.svg");
      background-repeat: no-repeat;
      width: 61px;
      height: 60px;
      background-size: 61px 61px;
      margin: 10px 0 28px; }
    .featuredInfo .container .mail, .featuredInfo .container .phone {
      position: relative; }
      .featuredInfo .container .mail .picture, .featuredInfo .container .phone .picture {
        width: 20px;
        height: 20px;
        background-size: 20px;
        display: inline-block;
        margin: 0px 12px 0 0; }
      .featuredInfo .container .mail .font_body2, .featuredInfo .container .phone .font_body2 {
        display: inline-block;
        position: absolute;
        top: -2px; }
      .featuredInfo .container .mail.mail, .featuredInfo .container .phone.mail {
        margin-bottom: 10px; }
      .featuredInfo .container .mail.phone, .featuredInfo .container .phone.phone {
        margin-bottom: 25px; }

.fullLayout .featuredInfo {
  width: 100%; }
