/* Brand Colors */
/* Color palette */
/* Gray colors */
@media (max-width: 600px) {
  .layout {
    padding: 0px 20px 20px 20px; } }

@media (min-width: 601px) and (max-width: 1023px) {
  .layout {
    width: 520px;
    margin: 0 auto; } }

@media (min-width: 1024px) and (max-width: 1120px) {
  .layout {
    width: calc(100% - 140px);
    margin: 0 66px; } }

@media (min-width: 1121px) {
  .layout {
    width: 990px;
    margin: 0 auto; } }

.layout .leftLayout {
  display: inline-block;
  width: 100%;
  left: 0;
  float: left; }
  @media (min-width: 601px) and (max-width: 1023px) {
    .layout .leftLayout {
      width: 100%; } }
  @media (min-width: 1024px) and (max-width: 1120px) {
    .layout .leftLayout {
      width: 520px; } }
  @media (min-width: 1121px) {
    .layout .leftLayout {
      width: 520px; } }

.layout .rightLayout {
  float: right;
  display: inline-block;
  width: 100%;
  right: 0; }
  @media (min-width: 601px) and (max-width: 1023px) {
    .layout .rightLayout {
      width: 100%; } }
  @media (min-width: 1024px) and (max-width: 1120px) {
    .layout .rightLayout {
      width: 320px;
      margin: 0 auto; } }
  @media (min-width: 1121px) {
    .layout .rightLayout {
      width: 320px; } }

.fullLayout {
  width: 600px;
  margin: 0 auto; }
  @media (max-width: 600px) {
    .fullLayout {
      width: calc(100% - 40px);
      padding: 0 20px; } }

* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/ }
