/* Brand Colors */
/* Color palette */
/* Gray colors */
.status {
  margin: 0 auto;
  width: 100%;
  display: inline-block; }
  .status .center {
    text-align: center;
    color: #4a4a4a; }
  .status .titleIcon {
    margin: 40px auto;
    margin-bottom: 28px;
    width: 100px;
    height: 92px; }
