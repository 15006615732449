/* Brand Colors */
/* Color palette */
/* Gray colors */
.providersInfo {
  margin: 20px 0 30px;
  float: left;
  width: 100%; }
  .providersInfo .provider {
    width: 50%; }
    .providersInfo .provider.left {
      float: left; }
    .providersInfo .provider.right {
      float: right;
      text-align: right; }
    .providersInfo .provider .imageContainer {
      height: 100px;
      vertical-align: middle;
      display: table-cell; }
      .providersInfo .provider .imageContainer .picture {
        max-height: 100px;
        max-width: 100px; }
