/* Brand Colors */
/* Color palette */
/* Gray colors */
.secondary {
  color: #0E908D; }

.error {
  color: #ef5160; }

.digest {
  margin-bottom: 40px; }
  .digest .container .pictureContainer {
    width: 100%;
    height: 186px; }
    .digest .container .pictureContainer .picture {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 3px 3px 0 0; }
    .digest .container .pictureContainer .tabPic {
      margin: 0 auto;
      width: calc(100% - 40px);
      position: absolute;
      text-align: center; }
      @media (min-width: 601px) and (max-width: 1023px) {
        .digest .container .pictureContainer .tabPic {
          width: 520px; } }
      @media (min-width: 1024px) and (max-width: 1120px) {
        .digest .container .pictureContainer .tabPic {
          width: 520px; } }
      @media (min-width: 1121px) {
        .digest .container .pictureContainer .tabPic {
          width: 520px; } }
  .digest .container .borderPic {
    height: 4px;
    background-image: -webkit-linear-gradient(181deg, #A66ECC, #10c0bc);
    background-image: -o-linear-gradient(181deg, #A66ECC, #10c0bc);
    background-image: linear-gradient(271deg, #A66ECC, #10c0bc);
    width: 100%;
    position: relative;
    z-index: 2; }
  .digest .container .bookingContainer {
    border: 1px solid #CDD2D4;
    margin-top: -1px;
    position: relative;
    z-index: 1;
    padding: 20px 20px 20px; }
    .digest .container .bookingContainer .font_headline6 {
      margin-bottom: 20px;
      color: #4a4a4a; }
    .digest .container .bookingContainer .code {
      color: #4a4a4a;
      margin-bottom: 12px;
      position: relative;
      margin-bottom: 12px; }
      .digest .container .bookingContainer .code.dialogMargin {
        margin-bottom: 4px; }
      .digest .container .bookingContainer .code .picture {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-size: 20px;
        display: inline-block; }
      .digest .container .bookingContainer .code .font_caption1 {
        display: inline-block;
        position: absolute;
        top: 2px; }
    .digest .container .bookingContainer .cancel {
      color: #4a4a4a;
      margin-bottom: 12px; }
    .digest .container .bookingContainer .containerPrice {
      width: 100%; }
    .digest .container .bookingContainer .buttonContainer {
      width: 100%;
      margin-top: 21px; }
    .digest .container .bookingContainer .separator {
      width: 100%;
      height: 1px;
      background-color: #CDD2D4;
      margin: 19px 0px 21px 0px; }

.modalContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column; }

.modalTitle {
  padding-top: 8px;
  padding-bottom: 12px; }
