/* Brand Colors */
/* Color palette */
/* Gray colors */
.font_headline0 {
  font-family: "Montserrat", sans-serif;
  font-display: auto;
  font-size: 120px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 120px;
  letter-spacing: -5px; }

.font_headline1 {
  font-family: "Montserrat", sans-serif;
  font-display: auto;
  font-size: 94px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 94px;
  letter-spacing: -3.92px; }

.font_headline2 {
  font-family: "Montserrat", sans-serif;
  font-display: auto;
  font-size: 60px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 60px;
  letter-spacing: -2.5px; }

.font_headline3 {
  font-family: "Montserrat", sans-serif;
  font-display: auto;
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 48px;
  letter-spacing: -2px; }

.font_headline4 {
  font-family: "Montserrat", sans-serif;
  font-display: auto;
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 34px;
  letter-spacing: -1.33px; }

.font_headline5 {
  font-family: "Montserrat", sans-serif;
  font-display: auto;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: -1px; }

.font_headline6 {
  font-family: "Montserrat", sans-serif;
  font-display: auto;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: -0.83px; }

.font_subtitle1 {
  font-family: "Open Sans", sans-serif;
  font-display: auto;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: 0; }

.font_subtitle2 {
  font-family: "Open Sans", sans-serif;
  font-display: auto;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0; }

.font_subtitle3 {
  font-family: "Open Sans", sans-serif;
  font-display: auto;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0; }

.font_body1 {
  font-family: "Open Sans", sans-serif;
  font-display: auto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 0; }

.font_body2 {
  font-family: "Open Sans", sans-serif;
  font-display: auto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0; }

.font_body3 {
  font-family: "Open Sans", sans-serif;
  font-display: auto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: 0; }

.font_button1 {
  font-family: "Montserrat", sans-serif;
  font-display: auto;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: -0.2px; }

.font_button2 {
  font-family: "Montserrat", sans-serif;
  font-display: auto;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: -0.15px; }

.font_caption1 {
  font-family: "Open Sans", sans-serif;
  font-display: auto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0; }

.font_caption2 {
  font-family: "Open Sans", sans-serif;
  font-display: auto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0; }

.font_h1 {
  font-family: "Montserrat", sans-serif;
  font-display: auto;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: -1px; }
  @media (min-width: 601px) and (max-width: 1023px) {
    .font_h1 {
      font-family: "Montserrat", sans-serif;
      font-display: auto;
      font-size: 32px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 34px;
      letter-spacing: -1.33px; } }
  @media (min-width: 1024px) and (max-width: 1120px) {
    .font_h1 {
      font-family: "Montserrat", sans-serif;
      font-display: auto;
      font-size: 48px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 48px;
      letter-spacing: -2px; } }
  @media (min-width: 1121px) {
    .font_h1 {
      font-family: "Montserrat", sans-serif;
      font-display: auto;
      font-size: 48px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 48px;
      letter-spacing: -2px; } }
